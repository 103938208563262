module.exports = {
  ZY: '/saas-custom-api', // 非标项目后端nginx做转发
  CARDBAG: '/api', // 旧平台卡包
  AUTHENTICATION: '/saas-server-auth',
  GATEWAY: '/saas-server-gateway',
  AMS: '/saas-core-ams',
  BMS: '/saas-core-bms',
  CMS: '/saas-core-cms',
  IMS: '/saas-core-ims',
  SCM: '/saas-core-scm',
  MMS: '/saas-core-public/saas-core-mms',
  OMS: '/saas-core-oms',
  ORGANIZATION: '',
  PMS: '/saas-core-pms',
  FMS: '/saas-core-fms',
  FILE: '/saas-server-file',
  AUTH: 'saas-server-auth',
  EWS: '/saas-core-ews',
  API: '/saas-core-api',
  public: '/saas-core-public',
  RMS: '/saas-core-rms',
  ASS: '/saas-core-ass',
  RC: '/recharge-customer',
}
